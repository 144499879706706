<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div id="userManagementApp">
      <form autocomplete="off" class="form-horizontal" id="UserFacilityDetails" v-cloak>
        <div class="dashboard">
            <div id="tab_section">
                <v-expand-transition>
                  <Tabs
                    :tabs="tabs"
                    colorTheme="light"
                  />
                </v-expand-transition>
            </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import EventBus from '@/app/shared/event-bus.js'
import Tabs from '@/app/shared/components/Tabs.vue'
import Breadcrumbs from '@/app/shared/components/Breadcrumbs.vue'

export default {
  name: 'UserManagement',
  data () {
    return {
      breadcrumbs: [
        {
          label: 'Dashboard',
          link: '/dashboard/overview',
          class: ''
        },
        {
          label: 'user_management',
          link: '',
          class: 'active'
        }
      ],
      tabs: [
        {
          tab_title: 'Facility Administration',
          to_link: '/Dashboard/UserManagement/FacilityAdministration',
          alternate_title: '/Dashboard/UserManagement/FacilityAdministration'
        },
        {
          tab_title: 'Add Facility',
          to_link: '/Dashboard/UserManagement/AddFacility',
          alternate_title: '/Dashboard/UserManagement/AddFacility'
        },
        {
          tab_title: 'Merge Health Facility',
          to_link: '/Dashboard/UserManagement/MergeHealthFacility',
          alternate_title: '/Dashboard/UserManagement/AddFacility'
        },
        {
          tab_title: 'My Profile',
          to_link: '/Dashboard/UserManagement/MyProfile',
          alternate_title: 'MyProfile'
        },
        {
          tab_title: '_reset_password',
          to_link: '/Dashboard/UserManagement/ResetPassword',
          alternate_title: 'ResetPassword'
        }
      ]
    }
  },
  async mounted () {
    EventBus.$on('LANGUAGE_CHANGE', this.forceReloadTabs)
    this.$refs.tabs.changeTab(0)
  },
  components: {
    Tabs,
    Breadcrumbs
  }
}
</script>

<style>

</style>
